<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <h4 class="page-title">Trainee-to-Trainer Assessment</h4>
        </div>
      </div>
    </div>
    <!-- end page title -->
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <!-- advance serach -->
            <div class="d-flex mb-3">
              <div class="d-flex flex-column me-2">
                <label class="col-form-label">Trainer</label>
                <div style="width: 180px">
                  <v-select
                    label="name"
                    v-model="employee"
                    :options="employees"
                    :clearable="false"
                    :selectable="(options) => employee.id != options.id"
                    @option:selected="FilterData(ori_batch, employee)"
                    :loading="!employees.length"
                  >
                  </v-select>
                </div>
              </div>
              <div class="d-flex flex-column me-2">
                <label class="col-form-label">Orientation Batch</label>
                <div style="width: 180px">
                  <v-select
                    label="batch"
                    v-model="ori_batch"
                    :options="ori_batchs"
                    :clearable="false"
                    :selectable="(options) => ori_batch.id != options.id"
                    @option:selected="FilterData(ori_batch, employee)"
                    :loading="!ori_batchs.length"
                  >
                  </v-select>
                </div>
              </div>

              <div class="d-flex mt-4">
                <!-- <div class="col-form-label d-none">hfhd</div> -->
                <div @click="refreshData()" class="icon-css">
                  <i style="color: #4a81d4" class="fe-rotate-cw"></i>
                </div>
              </div>
            </div>
            <!--end advance serach -->

            <place-holder v-if="loading"></place-holder>
            <div class="table-responsive">
              <table
                class="table table-striped dt-responsive w-100 mb-3"
                id="trainee-to-trainer-assessment-datatable"
                v-show="!loading"
              >
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>Trainer Name</th>
                    <th>Orientation Batch</th>
                    <th>Assessor</th>
                    <th v-if="hasPermissions(['trainer-edit'])">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in peertopeer" :key="index">
                    <td>{{ ++index }}</td>
                    <td>{{ item.trainer_employee_name }}</td>
                    <td>{{ item.batch }}</td>
                    <td>{{ item.assessor_employee_name }}</td>
                    <td v-if="hasPermissions(['trainer-edit'])">
                      <router-link
                        :to="{
                          name: 'trainee-to-trainer-assessment-detail',
                          params: { id: item.id },
                        }"
                      >
                        <button
                          type="button"
                          class="btn btn-xs btn-blue waves-effect waves-light mb-2"
                        >
                          <i class="fas fa-eye"></i>
                          View
                        </button>
                      </router-link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//Datatable Orientation Criteria
import "datatables.net/js/jquery.dataTables.min.js";

import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import "datatables.net-bs5/js/dataTables.bootstrap5.min.js";
import "datatables.net-responsive-bs5/css/responsive.bootstrap5.min.css";
import "datatables.net-responsive-bs5/js/responsive.bootstrap5.min.js";
import $ from "jquery";
import axios from "axios";
import { useToast } from "vue-toastification";
// import badgeSuccess from "../../../components/shared/badgeSuccess.vue";
// import badgeDanger from "../../../components/shared/badgeDanger.vue";

//Mixins
import userHasPermissions from "../../../mixins/userHasPermissions";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
  components: {
    vSelect,
  },
  mixins: [userHasPermissions],
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      peertopeer: [],
      employees: [],
      ori_batchs: [],
      employee: "",
      ori_batch: "",
      loading: false,
      baseUrl: process.env.VUE_APP_BASE_URL,
    };
  },
  methods: {
    async getAllTraineeToTrainers() {
      this.loading = true;
      this.$Progress.start();
      await axios
        .get(`${this.baseUrl}admin/v2/get-all-trainee-to-trainer-assessment`)
        .then((response) => {
          this.peertopeer = response.data.data;
          this.loading = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
          this.toast.error("Something Went Wrong!");
        });
      $("#trainee-to-trainer-assessment-datatable").DataTable().draw();
    },
    clearFilter() {
      $.fn.dataTable.ext.search.pop();
      $("#trainee-to-trainer-assessment-datatable").DataTable().draw();
    },
    async getOrientationBatchData() {
      this.loading = true;
      this.$Progress.start();
      await axios
        .get(`${this.baseUrl}admin/v2/orientation-award-get-fill-data`)
        .then((response) => {
          this.ori_batchs = response.data.data;
          this.loading = false;
          this.$Progress.finish();
        })
        .catch(() => {
          this.toast.error("Not Found Batch!");
          this.loading = false;
          this.$Progress.finish();
        });
    },
    FilterData(ori_batch, employee) {
      this.clearFilter();

      $.fn.dataTable.ext.search.push(function (settings, data) {
        const check_employee = employee?.name == data[1];
        const check_ori_batch = ori_batch?.batch == data[2];

        return (check_employee || !employee) && (check_ori_batch || !ori_batch)
          ? true
          : false;
      });
      $("#trainee-to-trainer-assessment-datatable").DataTable().draw();
    },
    refreshData() {
      this.ori_batch = "";
      this.employee = "";
      this.peertopeer = [];
      this.clearFilter();
      this.getAllTraineeToTrainers();
    },
  },
  created() {
    this.clearFilter();
    this.getOrientationBatchData();
    this.getAllTraineeToTrainers();
    let intervalId;
    if (!this.employees.length) {
      intervalId = setInterval(() => {
        this.employees = this.$store.getters["odoo/getAllEmployees"];
        if (this.employees.length) {
          clearInterval(intervalId);
        }
      }, 1000);
    }
  },
};
</script>

<style>
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #4a81d4;
  border-color: #4a81d4;
}
</style>
